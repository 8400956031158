import React from 'react'
import './about.css'
import ME from '../../assets/ap-about.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'
import {FaBook} from 'react-icons/fa'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className='container about_container'>
        <div className="about_me">
          <div className="about_me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
        
        <div className="about_content">
          <div className="about_cards">

            <article className='about_card'>
              <FaBook className='about_icon'/>
              <h5>Education</h5>
              <small>Computer Science, B.S.<br></br></small>
              <small>Cyber Security, Certificate</small>

            </article>

            {/* <article className='about_card'>
              <FiUsers className='about_icon'/>
              <h5>Clients</h5>
              <small>200+ Worldwide</small>
            </article> */}

            {/* <article className='about_card'>
              <VscFolderLibrary className='about_icon'/>
              <h5>AWS Certificates</h5>
              <small>80+ Completed</small>
            </article> */}
          </div>

          <p>
            I'm Audrey Pino, a Computer Science student and tech enthusiast. 
            Hopefully the sections below will give you an idea of who I am and my (growing) computer science experience. 
            {/* Please feel free to reach out if you have any questions, comments, or want to get to know me better! */}
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About