import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/ap.png'
import HeaderSocial from './HeaderSocials'

const Header = () => {
  return (
    <header>
      <div className="container header_container">
        <h5>Hello I'm</h5>
        <h1>AUDREY PINO</h1>
        <h5 className="text-light">Developer & Designer</h5>
        <CTA />
        <HeaderSocial />

        <div className="me">
          <img src={ME} alt="me" />
        </div>

        <a href= "#contact" className='scroll_down'>Contact Me</a>
      </div>
    </header>
  )
}

export default Header