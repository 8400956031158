import React from 'react'
import './experience.css'
import {BiCheck} from 'react-icons/bi'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>Relavent Coursework</h5>
      <h2>Experience</h2>

      <div className='container experience_container'>
        <article className='experience'>
          <div className='service_head'>
            <h3>Computer Science</h3>
          </div>

          <ul className='service_list'>
            <li>
              <BiCheck className='service_list-icon' />
              <p>Object-Oriented Programming</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Software Engineering</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Computer Architecture</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Data Structures and Algorithms</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Operating Systems</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Programming Language Paradigms</p>
            </li>

          </ul>
        </article>

        <article className='experience'>
          <div className='service_head'>
            <h3>Cyber Security</h3>
          </div>

          <ul className='service_list'>
            <li>
              <BiCheck className='service_list-icon' />
              <p>Cyber Defense</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Communication Networks</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Information and Computer Security</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Computer Forensics</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Database Systems</p>
            </li>

          </ul>
        </article>

        <article className='experience'>
          <div className='service_head'>
            <h3>AWS Certifications</h3>
          </div>

          <ul className='service_list'>
            <li>
              <BiCheck className='service_list-icon' />
              <p>Certified Cloud Practitioner</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>Technology Serverless</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>AWS Partner: Accredidation (Technical)</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>AWS Partner: Sales Accredidation (Business)</p>
            </li>

            <li>
              <BiCheck className='service_list-icon' />
              <p>AWS Partner: Accredidation (Cloud Economics)</p>
            </li>

          </ul>
        </article>
      </div>
    </section>
  )
}
export default Experience