import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_nk1f6oe', 'template_rqfwtda', form.current, 'uKSkneRDLjLdfej3A')

    e.target.reset();
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className='container contact_container'>
        <div className='contact_options'>
          <article className='contact_option'>
            <MdOutlineEmail className='contact_option-icon'/>
            <h4>Email</h4>
            <h5>pinoaudreyl@gmail.com</h5>
            <a href="mailto:pinoaudreyl@gmail.com" target="_blank">Send a Message</a>
          </article>

          <article className='contact_option'>
          <MdOutlineEmail className='contact_option-icon'/>
            <h4>School Email</h4>
            <h5>pinoaudreyl@gmail.com</h5>
            <a href="mailto:pinoaudreyl@gmail.com" target="_blank">Send a Message</a>
          </article>

          <article className='contact_option'>
          <MdOutlineEmail className='contact_option-icon'/>
            <h4>Phone</h4>
            <h5>+13032651552</h5>
            <a href="mailto:pinoaudreyl@gmail.com" target="_blank">Send a Message</a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS */}
        <form ref={form} onSubmit={sendEmail}>
          <input type='text' name='name' placeholder='Your Full Name' required/>
          <input type='email' name='email' placeholder='Your Email' required/>
          <textarea name='message' placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact