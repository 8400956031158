import React from 'react'
import './resume.css'
import RH from '../../assets/rh.jpg'
import PAX8 from './pax8.jpeg'

import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails, {
  accordionDetailsClasses,
} from '@mui/joy/AccordionDetails';
import AccordionSummary, {
  accordionSummaryClasses,
} from '@mui/joy/AccordionSummary';


import Typography from '@mui/joy/Typography'
import Avatar from '@mui/joy/Avatar';

const data = [
    {
      id: 1,
      image: PAX8,
      title: 'Pax8 - AWS Alliance Associate',
      date: '08.2023 - Present',
      description: 'Continue to streamline and build automation templates for login credentialing and other key areas of payer and distribution accounts on AWS Cloud. Work with AWS team on relevant Infrastructure as Code (IaC) projects.',
      skills: ['AWS', 'CloudFormation', 'Cloud Practitioner', 'Python']
    },
    {
        id: 2,
        image: PAX8,
        title: 'Pax8 - AWS Engineering Intern',
        date: '06.2023 - 08.2023',
        description: 'Improve cloud infrastructure for payer and distribution accounts. Prioritize the law of least privilege and AWS best practices. Eliminated single point of failure. Ensured robust & consistent security. Ensured security by retaining documentation of user activity and API usage. Reduced service desk tickets.',
        skills: ['AWS', 'CloudFormation', 'Cloud Practitioner', 'Python']
      },
      {
        id: 3,
        image: PAX8,
        title: 'Pax8 - Junior Software Engineer',
        date: '08.2022 -06.2023',
        description: 'Focused on creating new processes and system automation tools for Media Production Team. Developing scripts, automation forms, software, working to help improve the way our live and recorded media events are produced. •	Created dynamic scripting Developing scripts, automation forms, software, working to help improve the way our live and recorded media events are produced.',
        skills: ['vMix', 'XML', 'JavaScript', 'CSS', 'HTML']
      },
      {
        id: 4,
        image: PAX8,
        title: 'Pax8 - Studio Intern',
        date: '06.2022 -08.2022',
        description: '•	Pax8 Live Team. Helped Pax8 Live Producers with weekly streaming events. Introduced automation tools and helped to streamline producer’s dashboard.',
        skills: ['vMix', 'XML', 'JavaScript', 'CSS', 'HTML']
      },
      {
        id: 5,
        image: RH,
        title: 'Pax8 - Research Intern',
        date: '06.2019 - 08.2019',
        description: 'Extracted quantitative data for local Information Technology consulting group. Developed analytical project proposal around our AWS Competency Program to track technical proficiency and customer success stories for AWS Partner Network (APN).',
        skills: ['AWS', 'EXCEL']
      },
  ];
  
  const Resume = () => {
    return (
        <section id='resume'>
          <h5>My Experience</h5>
          <h2>Resume</h2>
    
          <div className='container resume_container'>
            {data.map((experience) => (
              <AccordionGroup
                key={experience.id}
                sx={{
                  [`& .${accordionSummaryClasses.button}:hover`]: {
                    bgcolor: 'var(--color-bg)',
                    border: '1px solid var(--color-primary-varient)',
                  },
                }}
              >
                <Accordion>
                  <AccordionSummary
                    sx={{
                      color: 'var(--color-light)',
                      background: 'var(--color-bg-varient)',
                      border: '1px solid transparent',
                      // borderRadius: '25px 25px 25px 25px',

                    //   '&:hover': {
                    //     bgcolor: 'var(--color-bg)',
                    //     border: '1px solid var(--color-primary-varient)',
                    //   }
                    }}
                  >
                    <Avatar className='image' src={experience.image}></Avatar>
                    <Typography 
                    level="h3"
                    sx={{
                        color: 'var(--color-light)',
                        fontWeight: '500',
                    }}
                    >{experience.title}</Typography >
                    <Typography 
                    level="h4"
                    sx={{
                        color: 'var(--color-light)',
                        fontWeight: '500',
                    }}
                    >
                      <i>{experience.date}</i>
                    </Typography>
                  </AccordionSummary>
    
                  <AccordionDetails
                    sx={{
                        color: 'var(--color-light)',
                        background: 'var(--color-bg-varient)',
                        borderRadius: '0px 0px 25px 25px',
                        border: '1px solid var(--color-primary-varient)',
                    }}
                  >
                    <p>
                      <br></br>
                      {experience.description}
                    </p>
                    <div className="resume_item-skill">
                      {experience.skills.map((skill, index) => (
                        <span key={index} className='skill'>
                          {skill}
                        </span>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </AccordionGroup>
            ))}
          </div>
        </section>
      );
    };

export default Resume