import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {PiCertificateFill} from 'react-icons/pi'

const HeaderSocials = () => {
  return (
    <div className='header_socials'>
        <a href="https://www.linkedin.com/in/audreypino/" target="_blank"><BsLinkedin/></a>
        <a href="https://github.com/pinoaudrey" target="_blank"><FaGithub/></a>
        <a href="https://www.credly.com/users/audrey-pino" target="_blank"><PiCertificateFill/></a>
    </div>
  )
}

export default HeaderSocials