import React from 'react'
import './skills.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Skills = () => {
  return (
    <section id='skills'>
      <h5>What I Know</h5>
      <h2>My Skills</h2>

      <div className="container skills_container">
        <div className="skills_frontend">
          <h3>Frontend Development</h3>
          <div className="skills_content">
            <article className='skills_details'>
              <BsPatchCheckFill className='skills_details-icon' />
              <div>
                <h4>HTML</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='skills_details'>
              <BsPatchCheckFill className='skills_details-icon'/>
              <div>
                <h4>JavaScript</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='skills_details'>
              <BsPatchCheckFill className='skills_details-icon' />
              <div>
                <h4>CSS</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='skills_details'>
              <BsPatchCheckFill className='skills_details-icon' />
              <div>
                <h4>Photoshop</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='skills_details'>
              <BsPatchCheckFill className='skills_details-icon' />
              <div>
                <h4>React JS</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className='skills_details'>
              <BsPatchCheckFill className='skills_details-icon' />
              <div>
                <h4>Illustrator</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
          </div>
        </div>
{/* ============ Backend Development ============ */}
        <div className="experience_backend">
        <h3>Backend Development</h3>
          <div className="experience_content">
            <article className='skills_details'>
              <BsPatchCheckFill className='skills_details-icon' />
              <div>
                <h4>AWS</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='skills_details'>
              <BsPatchCheckFill className='skills_details-icon' />
              <div>
                <h4>C/C++</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='skills_details'>
              <BsPatchCheckFill className='skills_details-icon' />
              <div>
                <h4>Pyton</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className='skills_details'>
              <BsPatchCheckFill className='skills_details-icon' />
              <div>
                <h4>MySQL</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='skills_details'>
              <BsPatchCheckFill className='skills_details-icon' />
              <div>
                <h4>YAML</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='skills_details'>
              <BsPatchCheckFill className='skills_details-icon' />
              <div>
                <h4>JSON</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Skills