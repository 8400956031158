import React from 'react'
import './portfolio.css'
import mirror from '../../assets/mirror.png'
import record from '../../assets/record.png'
import apple from '../../assets/apple.png'

const data = [
  {
    id: 1,
    image: record,
    title: 'Modern Record Player',
    github: 'https://github.com/pinoaudrey/vinylRecord',
    demo: 'https://www.dribble.com/Alien_pixels'
  },
  {
    id: 2,
    image: mirror,
    title: 'Smart Mirror',
    github: 'https://github.com/pinoaudrey/smartMirror',
    demo: 'https://www.dribble.com/Alien_pixels'
  },
  {
    id: 3,
    image: apple,
    title: 'Apple Teardown Art',
    github: 'https://github.com/pinoaudrey/appleTearDown',
    demo: 'https://www.dribble.com/Alien_pixels'
  },
  // {
  //   id: 4,
  //   image: IMG4,
  //   title: 'This is a portfolio item title',
  //   github: 'https://www.github.com',
  //   demo: 'https://www.dribble.com/Alien_pixels'
  // },
  // {
  //   id: 5,
  //   image: IMG5,
  //   title: 'This is a portfolio item title',
  //   github: 'https://www.github.com',
  //   demo: 'https://www.dribble.com/Alien_pixels'
  // },
  // {
  //   id: 6,
  //   image: IMG6,
  //   title: 'This is a portfolio item title',
  //   github: 'https://www.github.com',
  //   demo: 'https://www.dribble.com/Alien_pixels'
  // },
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio_container">
        {
          data.map(({id, image, title, github, demo}) => {
            return (
              <article key={id} className="portfolio_item">
                <div className="portfolio_item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className="portfolio_item-cta">
                  <a href={github} className='btn' target='blank'>GitHub</a>
                  {/* <a href={demo} className='btn btn-primary' target='blank'>Live Demo</a> */}
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio